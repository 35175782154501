<template lang="html">
    <!-- 视频 新建 弹出 -->
    <el-dialog :visible="personnelVisible" :title="title" :modal-append-to-body="true" :append-to-body="true" :close-on-click-modal="false" width="650px" @close="close()">
        <el-form  ref="formAuth"  :rules="formRules" :model="formData" label-position="top" :label-width="formLabelWidth" inline size="small" class="alertForm">
            <el-form-item label="年级" prop="grade_id" style="width:31.3%;">
                <el-select v-model="formData.grade_id" placeholder="请选择年级" @change="onChange('grade',formData.grade_id)">
                    <el-option v-for="(item,index) in gradeList" :label="item.name" :value="item.id" :key="index"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="科目" prop="subjects_id" style="width:31.3%;margin-right:3%;">
                <el-select v-model="formData.subjects_id" placeholder="请选择科目" @change="onChange('subjects',formData.subjects_id)">
                    <el-option v-for="(item,index) in subjectsList" :label="item.name" :value="item.id" :key="index"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="章节" prop="chapter_id" style="width:31.3%;margin-right:0">
                <el-select v-model="formData.chapter_id" placeholder="请选择章节">
                    <el-option v-for="(item,index) in chapterList" :label="item.name" :value="item.id" :key="index"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="题号" prop="stem_no" style="width:31.3%;margin-right:0">
                <el-input v-model="formData.stem_no" size="small" placeholder="请输入题目编号">
                </el-input>
            </el-form-item>
            <el-form-item label="题目/视频(仅支持MP4格式)" :required="true" style="width:100%">
                <div v-for="(item,index) in video" :key="index" style="display:flex;flex-direction:column;">
                    <div class="" style="display:flex;width:100%;align-items:center">
                        <el-col :span="18">
                            <el-input type="textarea" :rows="2" resize="none" v-model="item.stem" size="small" placeholder="请输入题目">
                            </el-input>
                        </el-col>
                        <el-col :span="1" align="center">-</el-col>
                        <el-col :span="3">
                            <!-- <el-input v-model="item.savename" size="small" placeholder="请输入视频"></el-input> -->
                            <input type="file" ref="fileInput" :value="filevalue" accept="video/mp4" size="small" @change="upload($event,index)" style="width:95%" />
                        </el-col>
                        <el-col :span="2" align="center" style="display: flex;align-items: center;justify-content: center;height:40px;" v-if="!formData.id">
                            <i class="el-icon-circle-plus" style="font-size: 26px;color: #67C23A;" v-if="videoLength == parseInt(index+1)" @click="addAppend()"></i>
                            <i class="el-icon-remove" style="font-size: 26px;color: #F56C6C;" v-else @click="delAppend(index)"></i>
                        </el-col>
                    </div>
                    <p style="padding:0 5px;background:#fff;border-bottom: 1px dashed #ddd;margin-bottom: 10px;" :style="{'border-bottom':item.name?'1px dashed #ddd':'0px'}">{{item.name?item.name:''}}</p>
                </div>
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button size="small" @click="close()">取 消</el-button>
            <el-button size="small" type="primary" @click="submit()">确 定</el-button>
        </div>
    </el-dialog>
</template>

<script>
import * as qiniu from 'qiniu-js';
// import axios from 'axios';
export default {
    data() {
        return {
            video: [{
                stem: '',
                stem_no: '',
                savename: '',
                name: ''
            }],
            formData: {
                video: []
            }, //提交数据
            filevalue: '',
            formLabelWidth: '80px', //弹出form label 宽
            personnelVisible: false, //显隐
            // 表单验证
            formRules: {
                grade_id: [{
                    required: true,
                    message: '请选择年级',
                    trigger: 'blur,change'
                }],
                subjects_id: [{
                    required: true,
                    message: '请选择科目',
                    trigger: 'blur'
                }],
                chapter_id: [{
                    required: true,
                    message: '请选择章节',
                    trigger: 'blur'
                }],
                stem_no: [{
                    required: true,
                    message: '请输入题号',
                    trigger: 'blur'
                }],
            },

            uptoken: '',
            tudomain: '',
            bucket: '',

            gradeList: [],
            subjectsList: [],
            chapterList: [],
        }
    },
    props: [
        'title',
        // 'gradeList',
        // 'subjectsList',
        // 'chapterList'
    ],
    computed: {
        // 计算属性的 getter
        videoLength: function() {
            // `this` 指向 vm 实例
            return this.video.length;
        }
    },
    mounted() {
        this.init();
    },
    methods: {
        init() {
            this.http.post('/admin.qiNiu/getConfig').then((re) => {
                this.tudomain = re.domain;
                this.bucket = re.bucket
            });
            this.http.post('/admin.qiNiu/getToken').then(re => {
                this.uptoken = re
            })
            this.getSelectList('', 0);
        },
        onChange(type, parent_id) {
            this.getSelectList(type, parent_id);
        },
        getSelectList(type, parent_id) {
            this.http.post('/admin.setting/getSubList', {
                parent_id
            }).then(re => {
                if(type == 'grade') {
                    this.subjectsList = re.subjects;
                    this.$set(this.formData, 'subjects_id', '')
                    this.$set(this.formData, 'chapter_id', '')
                } else if(type == 'subjects') {
                    this.chapterList = re.chapter;
                    this.$set(this.formData, 'chapter_id', '')
                } else {
                    this.gradeList = re.grade;
                }
            })
        },

        getSelect(type, parent_id) {
            this.http.post('/admin.setting/getSubList', {
                parent_id
            }).then(re => {
                if(type == 'grade') {
                    this.subjectsList = re.subjects;
                } else if(type == 'subjects') {
                    this.chapterList = re.chapter;
                }
            })
        },
        close: function() {
            this.personnelVisible = false;
            this.$emit('close');
        },
        open: function() {
            this.personnelVisible = true;
        },
        success: function() {
            this.$emit('success');
        },
        getname() {
            console.log(this.$refs.fileInput.files[0]);
        },
        // 增加一条
        addAppend() {
            let index = this.video.length - 1;
            if(!this.video[index].stem || !this.video[index].savename) {
                this.$message.error('题目/视频不得为空！');
                return false;
            } else {
                var videoArr = {
                    stem: '',
                    savename: '',
                    name: ''
                };
                this.video.push(videoArr);
            }
        },

        // 删减一条
        delAppend(index) {
            this.video.splice(index, 1);
        },
        upload(event, index) { //
            var loads2 = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)',
                customClass: 'myLoading',
            });
            // var files = event.target.files[0];
            // var formData = new FormData();
            // formData.append('file', files);
            // axios.post(this.domain + '/admin.video/upload', formData).then(re => {
            //     loads2.close();
            //     if (re.data.code == 200) {
            //         this.filevalue = "";
            //         this.video[index].savename = re.data.data;
            //         this.video[index].name = event.target.files[0].name;
            //     } else if (re.data.code == 201) {
            //         this.$router.push('/login')
            //     } else {
            //         this.$message.error(re.data.msg);
            //     }
            // }).catch(function(error) { // 请求失败处理
            //     console.log(error);
            // });


            console.log(event.target.files[0]);
            let names = event.target.files[0].name;
            let key = new Date().getTime() + event.target.files[0].name; //这是上船后返回的文件名，这里为了避免重复，加上了时间戳和随机数+ Math.random(1000)
            var config = {
                useCdnDomain: true, //表示是否使用 cdn 加速域名，为布尔值，true 表示使用，默认为 false。
                region: qiniu.region.z1, //选择上传域名区域；当为 null 或 undefined 时，自动分析上传域名区域。  我这里是华东区
                domain: this.domain, //配置好的七牛云域名  如   https://cdn.qniyun.com/
                chunkSize: 100, //每个分片的大小，单位mb，默认值3
                forceDirect: true //直传还是断点续传方式，true为直传
            };
            var putExtra = {
                fname: event.target.files[0].name, //文件原始文件名
                params: {},
                mimeType: [] || null
                // ...config,
            };
            var observable = qiniu.upload(event.target.files[0], key, this.uptoken, putExtra, config);
            observable.subscribe({
                next: () => {
                    //主要用来展示进度
                    // loads2.close();
                },
                error: (err) => {
                    loads2.close();
                    //上传错误后触发
                    console.log(err);
                },
                complete: (result) => {
                    //上传成功后触发。包含文件地址。
                    loads2.close();
                    console.log(result);
                    this.filevalue = "";
                    this.video[index].savename = result.key;
                    this.video[index].name = names;
                },
            });
        },

        submit: function() {
            // 验证
            this.$refs['formAuth'].validate((valid) => {
                if(valid) {
                    if(this.formData.id) {
                        if(this.video.length == 1 && (this.video[0].stem == '' || this.video[0].savename == '')) {
                            this.$message.error('题目/视频不得为空');
                            return false;
                        }
                        this.$set(this.formData, 'stem', this.video[0].stem);
                        this.$set(this.formData, 'savename', this.video[0].savename);
                        this.$set(this.formData, 'name', this.video[0].name);
                        this.http.post('/admin.video/edit', this.formData).then(() => {
                            this.close();
                            this.success(); //父页面刷子你列表
                            this.formData = {}; //清空
                            this.video = [];
                            this.$message({
                                message: '提交成功!',
                                type: 'success'
                            });
                        });
                    } else {
                        let index = this.video.length - 1;
                        if(this.video.length == 1 && (this.video[0].stem == '' || this.video[0].savename == '')) {
                            this.$message.error('请至少上传一个题目/视频');
                        } else if(!this.video[index].stem || !this.video[index].savename) {
                            this.$message.error('题目/视频不得为空！');
                            return false;
                        }
                        this.formData.video = this.video;
                        this.http.post('/admin.video/batchSave', this.formData).then(() => {
                            this.close();
                            this.success(); //父页面刷子你列表
                            this.formData = {}; //清空
                            this.video = [];
                            this.$message({
                                message: '提交成功!',
                                type: 'success'
                            });
                        });
                    }
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        }
    }
}
</script>
