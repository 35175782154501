<template lang="html">
    <div style="height:100%">
        <!-- 第一行 搜索栏 -->
        <div class="handleBox flex flex-jc-sb flex-ai-c" @keyup.enter="curr=1;getList()">
            <p class="title">视频管理 ></p>
            <el-input placeholder="搜索题目" v-model="searchValue" size="small" class="input-with-select" style="width:300px">
                <el-button slot="append" icon="el-icon-search" @click="curr=1;getList()"></el-button>
            </el-input>
            <div class="">
                <el-button type="primary" size="small" icon="el-icon-circle-plus-outline" style="margin-right:10px" @click="edit()">添加视频</el-button>
            </div>
        </div>
        <!-- 第二行 表格操作栏 -->
        <div class="selectionBar">
            <div>
                <label>场景：</label>
                <el-select v-model="grade" placeholder="选择年级" clearable @change="onChange('grade',grade)">
                    <el-option v-for="(item,index) in gradeList" :label="item.name" :value="item.id" :key="index"></el-option>
                </el-select>
                <el-select v-model="subjects" placeholder="选择科目" clearable @change="onChange('subjects',subjects)">
                    <el-option v-for="(item,index) in subjectsList" :label="item.name" :value="item.id" :key="index"></el-option>
                </el-select>
                <el-select v-model="chapter" placeholder="选择章节" clearable @change="curr=1;getList()">
                    <el-option v-for="(item,index) in chapterList" :label="item.name" :value="item.id" :key="index"></el-option>
                </el-select>
            </div>
        </div>
        <!-- 表格 -->
        <div class="table" style="height:calc( 100% - 135px )">
            <el-table :data="list" highlight-current-row stripe border
                :header-cell-style="{background:'#fafdff',color:'#606266',fontWeight:'normal'}"
                @sort-change='getList()'
                height="calc( 100% - 41px )"
            >
				<el-table-column prop="stem_no" label="题号" fixed show-overflow-tooltip>
				</el-table-column>
                <el-table-column prop="stem" label="题目" width="300" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="name" label="视频"  width="500" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="view_num" label="播放量" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="grade" label="年级" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="subjects" label="科目" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="chapter" label="章节" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="create_time" label="创建时间" min-width="110" show-overflow-tooltip>
                </el-table-column>
                <el-table-column label="操作" align="center" width="220" fixed="right">
                    <template slot-scope="scope">
                        <el-button type="warning" plain size="mini" @click="show(scope.row)">播放</el-button>
                        <el-button type="primary" plain size="mini" @click="edit(scope.row)">编辑</el-button>
                        <el-button type="danger" plain size="mini" @click="del(scope.row.id)" >删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页 -->
            <div class="paging">
                <el-pagination @current-change="currentChange" @size-change="sizeChange" :current-page="curr" :page-size="row" background layout="total, sizes, prev, pager, next, jumper" :total="count"></el-pagination>
            </div>
        </div>

        <videoAdd ref="videoAdd" :title="title" :gradeList="gradeList" :subjectsList="subjectsList" :chapterList="chapterList" @success="getList()"></videoAdd>

        <el-dialog
                :modal-append-to-body="true" :append-to-body="true" :close-on-click-modal="false"
                title="视频播放"
                :visible.sync="DialogVisible"
                width="700px"
                center>
                <video :src="url" autoplay controls style="width:100%"></video>
        </el-dialog>
    </div>
</template>

<script>
import videoAdd from "../components/videoAdd.vue";
export default {
    components: {
        videoAdd
    },
    data: function () {
        return {
            title: '添加视频',
            searchValue: '', //导入input值
            grade: '',
            subjects: '',
            chapter: '',
            list: [],
            curr: 1, //页码
            row: 20, //每页条数
            pages: 1, //总页数
            count: 0, //总条数

            gradeList: [],
            subjectsList: [],
            chapterList: [],

            DialogVisible: false,
            url: ''
        }
    },
    mounted: function () {
        this.getList();
        this.getSelectList('', 0);
    },
    watch: {

    },
    methods: {
        onChange(type, parent_id) {
            this.getSelectList(type, parent_id);
            this.curr = 1;
            this.getList()
        },
        // 播放视频
        show(row) {
            this.http.post('/admin.video/getUrl', {
                url: row.savename,
                video_id: row.id
            }).then(re => {
                this.url = re.url;
                this.DialogVisible = true;
            })
        },
        getList: function () {
            this.http.post('/admin.video/getList', {
                curr: this.curr,
                row: this.row,
                search: this.searchValue,
                grade: this.grade,
                subjects: this.subjects,
                chapter: this.chapter
            }).then(re => {
                this.list = re.list;
                this.curr = re.curr;
                this.pages = re.pages;
                this.count = re.count;
            })
        },
        getSelectList(type, parent_id) {
            this.http.post('/admin.setting/getSubList', {
                parent_id
            }).then(re => {
                if (type == 'grade') {
                    this.subjectsList = re.subjects;
                    this.subjects = '';
                    this.chapter = '';
                } else if (type == 'subjects') {
                    this.chapterList = re.chapter;
                    this.chapter = '';
                } else {
                    this.gradeList = re.grade;
                }
            })
        },
        edit: function (row) {
            if (row) {
                this.title = '编辑视频';
                this.http.post('/admin.video/read', {
                    id: row.id
                }).then(re => {
                    console.log(this.formData);
                    if (re.subjects_id) {
                        this.$refs.videoAdd.getSelect('grade', re.grade_id);
                    }

                    if (re.chapter_id) {
                        this.$refs.videoAdd.getSelect('subjects', re.subjects_id);
                    }
                    this.$refs.videoAdd.formData = re;

                    this.$refs.videoAdd.video = [{
                        stem: re.stem,
                        savename: re.savename,
                        name: re.name
                    }];
                })
            } else {
                this.title = '添加视频';
                this.$refs.videoAdd.formData = {};
                this.$refs.videoAdd.video = [{
                    stem: '',
                    savename: '',
                    name: ''
                }];
            }
            console.log(this.$refs.videoAdd.formData);

            this.$refs.videoAdd.open();
        },
        // 删除
        del(id) {
            this.$confirm('确定删除选中行吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.http.post('/admin.video/delete', {
                    id
                }).then(() => {
                    this.getList();
                    this.$message({
                        type: 'success',
                        message: '成功删除!'
                    });
                })
            })
        },
        //翻页
        currentChange: function (curr) {
            this.curr = curr;
            this.getList();
        },
        //改变每页条数
        sizeChange: function (row) {
            this.row = row;
            this.getList();
        },
    }
}
</script>
